import { useMelwaysInfo } from '@seek/melways-react';
import { useMemo } from 'react';

import { useSERPHasExternalJobs } from 'src/modules/hooks/useSERPHasExternalJobs';
import { includedKeyword } from 'src/modules/related-search-seo/keyword-filters';
import { useSelector } from 'src/store/react';
import type { FacetType, RelatedSearch } from 'src/store/results/types';
import {
  selectFacets,
  selectJoraCrossLink,
  selectRelatedSearches,
  selectSearchQuery,
} from 'src/store/selectors';

export type FilterList =
  | { filterListType: 'related'; filteredSearches: RelatedSearch[] }
  | { filterListType: 'facets'; filteredSearches: FacetType[] }
  | undefined;

const useSeoFooter = () => {
  const hasExternalJobs = useSERPHasExternalJobs();
  const partners = useSelector(selectJoraCrossLink);
  const relatedSearches = useSelector(selectRelatedSearches);
  const facets = useSelector(selectFacets);
  const searchQuery = useSelector(selectSearchQuery);
  const { language: currentLanguage } = useMelwaysInfo();

  const isLocationOnlySerp =
    searchQuery &&
    !searchQuery.keywords &&
    !searchQuery.classification &&
    searchQuery.where;

  const shouldRenderDistinctTitleFacets =
    currentLanguage === 'en' && isLocationOnlySerp;

  const shouldRenderSeoPartners = partners != null;

  return useMemo(() => {
    const filteredSearches = relatedSearches
      ? relatedSearches.filter(({ keywords }) => includedKeyword(keywords))
      : [];

    const distinctTitleFacets =
      facets && facets.distinctTitle
        ? facets.distinctTitle.filter(
            ({ label }) => label && includedKeyword(label),
          )
        : [];

    let relatedList: FilterList;

    if (shouldRenderDistinctTitleFacets && distinctTitleFacets.length > 0) {
      relatedList = {
        filterListType: 'facets',
        filteredSearches: distinctTitleFacets,
      };
    } else if (filteredSearches.length > 0) {
      relatedList = { filterListType: 'related', filteredSearches };
    }

    const shouldRenderRelatedSearch =
      relatedList && relatedList?.filteredSearches.length > 0;

    return {
      tabIndex: -1,
      hasExternalJobs,
      partners,
      relatedList,
      shouldRenderRelatedSearch,
      shouldRenderSeoPartners,
    };
  }, [
    relatedSearches,
    facets,
    shouldRenderDistinctTitleFacets,
    hasExternalJobs,
    partners,
    shouldRenderSeoPartners,
  ]);
};

export default useSeoFooter;
