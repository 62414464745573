import {
  useApolloClient,
  type ApolloClient,
  type NormalizedCacheObject,
} from '@apollo/client';
import type { JobTagType } from '@seek/chalice-types';
import { mapJobTagsToBadges } from '@seek/seek-jobs-analytics';
import { useCallback } from 'react';

import { useSplitViewContext } from 'src/handlers/SplitViewContext';
import getRecommendedBadgesForTracking from 'src/hooks/getRecommendedJobAnalyticsBadges';
import {
  getIsAddSalaryPrefNudgeShown,
  getIsSalaryMatch,
} from 'src/hooks/trackJobDetailsLoaded';
import { useSolHash } from 'src/hooks/useSolHash';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import {
  useIsHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled,
  useIsHomepageRecsBadgingStrongApplicantEnabled,
} from 'src/store/featureFlags/hooks';
import type {
  JobDetails,
  PersonalisedJobDetails,
} from 'src/store/jobdetails/types';
import { useSelector } from 'src/store/react';
import {
  selectAuthenticated,
  selectLocation,
  selectSearchQuery,
  selectSolMetadata,
} from 'src/store/selectors';
import { getViewJobOriginRef } from 'src/utils/eventCapture/eventCaptureUtils';
import { mapDisplayTagsType, mergeTags } from 'src/utils/mapJobTagsType';
import { useMelwaysLocale } from 'src/utils/melwaysHelper';

import {
  RECOMMENDED_JOB_AI_VIEW_ORIGIN,
  RECOMMENDED_JOB_VIEW_ORIGIN,
} from '../HomePage/Dashboard/SignedInDashboard/DashboardRecommendations/RecommendedJobCard';

interface Options {
  jobDetails: JobDetails;
  isHirerVerified: boolean;
  jobDetailsPersonalised?: PersonalisedJobDetails;
  solMetadataReference?: string;
  isMatchedSkillsWidgetShown?: boolean;
  isMatchedSkillsNudgeShown?: boolean;
  jobOrigin?: string;
}

type UseApplyTracking = (options: Options) => [string, () => void];

const HOME_RECS_ALLOWED_ORIGIN = [
  RECOMMENDED_JOB_VIEW_ORIGIN,
  RECOMMENDED_JOB_AI_VIEW_ORIGIN,
];

const useJobBadgesTracking = (
  jobId: string,
  jobOrigin: string | undefined,
): JobTagType[] => {
  const selectedJob = useSplitViewContext()?.selectedJob;
  const apolloClient = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const location = useSelector(selectLocation);
  const locale = useMelwaysLocale();
  const isHomepageRecsBadgingStrongApplicantEnabled =
    useIsHomepageRecsBadgingStrongApplicantEnabled();
  const isHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled =
    useIsHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled();

  /**
   * Only applicable if user is in splitView since currently
   * there is no way to track jobTags if user is in standalone
   * job details page.
   */
  if (selectedJob) {
    return mapJobTagsToBadges(selectedJob.tags);
  }

  return [location.query?.ref, jobOrigin].some((target) =>
    HOME_RECS_ALLOWED_ORIGIN.includes(target),
  )
    ? getRecommendedBadgesForTracking({
        apolloClient,
        locale,
        jobId,
        isHomepageRecsBadgingStrongApplicantEnabled,
        isHomepageRecsBadgingExpiringSoonEarlyApplicantEnabled,
      })
    : [];
};

export const useApplyTracking: UseApplyTracking = ({
  jobDetails,
  jobDetailsPersonalised,
  solMetadataReference,
  isHirerVerified,
  isMatchedSkillsWidgetShown,
  isMatchedSkillsNudgeShown,
  jobOrigin,
}) => {
  const analyticsFacade = useAnalyticsFacade();
  const isAuthenticated = useSelector(selectAuthenticated);
  const solMetadata = useSelector(selectSolMetadata);
  const searchParams = useSelector(selectSearchQuery);

  const { id, isLinkOut, salary, products } = jobDetails?.job || {};
  const [urlStr, solMeta] = useSolHash({
    id,
    solMetadataReference,
  });

  const salaryMatch = jobDetailsPersonalised?.salaryMatch;
  const isSalaryMatch = getIsSalaryMatch(salaryMatch);
  const searchRemoteOptions = searchParams?.workarrangement;
  const isAddSalaryPrefNudgeShown = getIsAddSalaryPrefNudgeShown({
    isAuthenticated,
    isSalaryLabel: Boolean(salary?.label),
    salaryMatch,
  });

  const jobTags = useJobBadgesTracking(id, jobOrigin);
  const displayTags = mapDisplayTagsType(
    jobDetails?.job?.products?.displayTags,
  );
  const jobBadges = mergeTags({ jobCardTags: jobTags, jdvTags: displayTags });

  const experimentFlightId = solMetadata?.tags?.mordor__flights ?? '';

  const onApplyClick = useCallback(() => {
    /**
     * If there is no ref query in the URL and the user is
     * in splitView then it is garuanteed the user clicked
     * apply from split view SERP.
     */
    const viewJobOriginRef = getViewJobOriginRef();
    const ref = jobOrigin || viewJobOriginRef || 'search-split';

    analyticsFacade.jobApplyClicked(
      {
        experimentFlightId,
        href: urlStr,
        jobBadges,
        jobViewOriginQuery: ref,
        isHirerVerified,
        isMatchedSkillsWidgetShown,
        isMatchedSkillsNudgeShown,
        jobBadgesFilter: searchParams?.tags ? [searchParams?.tags] : [],
        jobCardSellPointCount: products?.bullets?.length,
      },
      {
        isAddSalaryPrefNudgeShown,
        isSalaryMatch,
        searchRemoteOptions,
        jobBadges,
        jobId: id,
        linkout: isLinkOut,
        solMetadata: solMeta,
        thirdPartyLink: isLinkOut,
        isMatchedSkillsWidgetShown,
        isMatchedSkillsNudgeShown,
      },
    );
  }, [
    jobOrigin,
    analyticsFacade,
    experimentFlightId,
    urlStr,
    isHirerVerified,
    isMatchedSkillsWidgetShown,
    isMatchedSkillsNudgeShown,
    isAddSalaryPrefNudgeShown,
    isSalaryMatch,
    searchRemoteOptions,
    id,
    isLinkOut,
    solMeta,
    jobBadges,
    searchParams?.tags,
    products?.bullets,
  ]);

  return [urlStr, onApplyClick];
};
