import type { SearchResultV5 } from '@seek/chalice-types';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { memo, useEffect, useRef, useState } from 'react';
import hash from 'string-hash';

import type { TGooglePublisherTag } from './types';
import { loadScript, type LoadScriptOptions } from './utils';

export type TargetingData = NonNullable<SearchResultV5['gptTargeting']>;

const DEFAULT_DEBOUNCE = 500;
const DEFAULT_RESIZE_DEBOUNCE = 100;

const useResizeAdListener = ({
  gpt,
  tagName,
  targetingData,
  responsiveSizing,
}: {
  gpt: TGooglePublisherTag | null;
  tagName: string;
  targetingData?: TargetingData;
  responsiveSizing?: GooglePublisherTagResponsiveSizing[];
}) => {
  const oldSize = useRef(window.innerWidth);
  const responsiveAdBreakpoints = responsiveSizing
    ?.filter((size) => size.minViewportWidth)
    .map((size) => Number(size.minViewportWidth));

  useEffect(() => {
    const throttleRefreshAd = throttle(refreshAd, DEFAULT_DEBOUNCE);

    const updateSize = () => {
      if (gpt && responsiveAdBreakpoints) {
        const shouldUpdate = responsiveAdBreakpoints.find((breakpoint) =>
          didSizeChange({
            newSize: window.innerWidth,
            breakpoint,
            oldSize: oldSize.current,
          }),
        );

        if (shouldUpdate) {
          throttleRefreshAd({
            gpt,
            tagName,
            targetingData,
          });
        }
      }
      oldSize.current = window.innerWidth;
    };

    const updateSizeThrottle = throttle(updateSize, DEFAULT_RESIZE_DEBOUNCE);

    if (gpt) {
      window.addEventListener('resize', updateSizeThrottle);
      return () => {
        window.removeEventListener('resize', updateSizeThrottle);
        throttleRefreshAd.cancel();
        updateSizeThrottle.cancel();
      };
    }
  }, [gpt, responsiveAdBreakpoints, targetingData, tagName]);
};

export type SingleSizeArray = [number, number];
const mapToSingleSizeArray = (sizes: GooglePublisherTagSize[]) => {
  // Using a set here to remove sizes duplicates
  const sizeGroups = sizes.reduce<Set<string>>(
    (allSizes, { height, width }) => {
      const sizeGroup = `${width}:${height}`;
      return allSizes.add(sizeGroup);
    },
    new Set(),
  );

  return Array.from(sizeGroups).map<SingleSizeArray>((size) => {
    const [width, height] = size.split(':').map(Number);
    return [width, height];
  });
};

interface ManageAdProps {
  gpt: TGooglePublisherTag;
  tagName: string;
}

interface didSizeChangeProps {
  newSize: number;
  oldSize: number | undefined;
  breakpoint: number;
}

export const didSizeChange = ({
  newSize,
  oldSize,
  breakpoint,
}: didSizeChangeProps): boolean => {
  if (!oldSize) {
    return false;
  }

  return (
    (oldSize < breakpoint && newSize > breakpoint) ||
    (oldSize > breakpoint && newSize < breakpoint)
  );
};

const getSlotByTagName = ({ gpt, tagName }: ManageAdProps) =>
  gpt
    .pubads()
    .getSlots()
    .find((s) => s.getSlotElementId() === tagName);

interface DefineSlotProps {
  gpt: TGooglePublisherTag;
  tagId: string;
  responsiveSizing: GooglePublisherTagResponsiveSizing[];
  tagName: string;
}

const defineSlot = ({
  gpt,
  tagId,
  responsiveSizing,
  tagName,
}: DefineSlotProps) => {
  const allSizes = responsiveSizing.reduce<GooglePublisherTagSize[]>(
    (acc, { sizes }) => acc.concat(sizes),
    [],
  );
  const sizeMap = mapToSingleSizeArray(allSizes);
  return gpt.defineSlot(tagId, sizeMap, tagName)?.addService(gpt.pubads());
};

interface MappingBuilderProps {
  gpt: TGooglePublisherTag;
  responsiveSizing: GooglePublisherTagResponsiveSizing[];
}

const mappingBuilder = ({ gpt, responsiveSizing }: MappingBuilderProps) => {
  const mapping = gpt.sizeMapping();
  responsiveSizing.map(({ minViewportWidth = 0, sizes }) => {
    const mappedResponsiveSizes = mapToSingleSizeArray(sizes);
    mapping.addSize([minViewportWidth, 0], mappedResponsiveSizes);
  });

  return mapping.build();
};

export const mappingBuilderForTest = mappingBuilder;

interface InitAdProps
  extends Omit<GooglePublisherTagProps, 'targetingData'>,
    ManageAdProps {}
export const initAd = ({
  gpt,
  tagName,
  tagId,
  responsiveSizing,
}: InitAdProps) => {
  gpt.cmd.push(() => {
    const slot = getSlotByTagName({
      gpt,
      tagName,
    });
    if (!slot) {
      const adSlot = defineSlot({ gpt, tagId, responsiveSizing, tagName });

      if (adSlot && responsiveSizing) {
        const mappingBuild = mappingBuilder({ gpt, responsiveSizing });
        if (mappingBuild) {
          adSlot.defineSizeMapping(mappingBuild);
        }
      }
      gpt.display(tagName);
    }
  });
};

interface RefreshAdProps
  extends Pick<GooglePublisherTagProps, 'targetingData'>,
    ManageAdProps {}
const refreshAd = ({ gpt, tagName, targetingData }: RefreshAdProps) => {
  gpt.cmd.push(() => {
    const slot = getSlotByTagName({
      gpt,
      tagName,
    });

    if (slot) {
      slot.clearTargeting();

      if (targetingData && Array.isArray(targetingData)) {
        targetingData.map(({ key, value }) => {
          slot.setTargeting(key, value);
        });
      }
      gpt.pubads().refresh([slot]);
    }
  });
};

interface GooglePublisherTagSize {
  width: number;
  height: number;
}

interface GooglePublisherTagResponsiveSizing {
  minViewportWidth?: number;
  sizes: GooglePublisherTagSize[];
}

interface GooglePublisherTagProps {
  tagId: string;
  responsiveSizing: GooglePublisherTagResponsiveSizing[];
  targetingData?: TargetingData;
  /**
   * The callback function to be invoked when an ad becomes viewable.
   * See: https://developers.google.com/publisher-tag/reference#googletag.events.ImpressionViewableEvent for more details
   */
  eventImpressionViewable?: LoadScriptOptions['eventImpressionViewable'];
}

const GooglePublisherTagView: React.FC<GooglePublisherTagProps> = (props) => {
  const { tagId, targetingData, responsiveSizing, eventImpressionViewable } =
    props;
  const [gpt, setGpt] = useState<TGooglePublisherTag | null>(null);
  const tagName = hash(tagId).toString();

  useResizeAdListener({ gpt, tagName, targetingData, responsiveSizing });

  useEffect(() => {
    const loadAndInit = async () => {
      const tag = await loadScript({
        eventImpressionViewable,
      });
      setGpt(tag);
      initAd({
        gpt: tag,
        tagName,
        tagId,
        responsiveSizing,
      });
    };
    loadAndInit();
  }, [tagId, tagName, responsiveSizing, eventImpressionViewable]);

  useEffect(() => {
    const debounceRefreshAd = debounce(refreshAd, DEFAULT_DEBOUNCE);

    if (gpt) {
      debounceRefreshAd({
        gpt,
        tagName,
        targetingData,
      });
    }

    return () => {
      debounceRefreshAd.cancel();
    };
  }, [gpt, tagId, tagName, targetingData]);

  return <div data-automation={`ad-${tagId}`} id={tagName} />;
};

export const GooglePublisherTag = memo(GooglePublisherTagView);
