import { Box, Column, Columns } from 'braid-design-system';
import { useEffect, useRef } from 'react';

import { useSplitViewContext } from 'src/handlers/SplitViewContext';
import { useFeatureFlagRefineBarV2Experiment } from 'src/store/featureFlags/hooks.ts';
import { useSelector } from 'src/store/react';
import { selectDynamicSearchBarType } from 'src/store/search/selectors';
import {
  selectIsResultsLoading,
  selectPaginationTotalPages,
  selectResults,
} from 'src/store/selectors';

import RelatedSearches from '../../RelatedSearches/RelatedSearches';
import { SearchResultsHeaderSpacer } from '../../SearchResultHeaders/SearchResultsHeaders';
import useSeoFooter from '../../SeoFooter/useSeoFooter';

import { Footer, LIST_FOOTER_ITEM_SPACING } from './Footer';

import * as styles from './SplitViewLayout.css';

const outerSpacing = ['none', 'none', 'xxlarge'] as const;

const SplitViewLayout = ({
  ListView,
  DetailView,
}: {
  ListView: React.ReactNode;
  DetailView: React.ReactNode;
}) => {
  const { selectedJob, isLoading } = useSplitViewContext();
  const shouldShowInitState = !Boolean(selectedJob);
  const { relatedList, shouldRenderRelatedSearch } = useSeoFooter();

  const totalPages = useSelector(selectPaginationTotalPages);
  const shouldHavePagination = totalPages > 1;
  const jobDetailsPaneElement = useRef<HTMLDivElement>(null);
  const { refineBarV2, stickySearchBar } =
    useFeatureFlagRefineBarV2Experiment();

  const dynamicSearchBarType = useSelector(selectDynamicSearchBarType);
  const isSearchLoading = useSelector(selectIsResultsLoading);
  const searchResultsViewModel = useSelector(selectResults);
  const { isZeroResults } = searchResultsViewModel || {};
  const hideResults = isSearchLoading || isZeroResults;

  useEffect(() => {
    if (!isLoading && jobDetailsPaneElement.current?.scrollTo) {
      jobDetailsPaneElement.current.scrollTo({
        top: 0,
      });
    }
  }, [isLoading]);

  return (
    <Box
      width="full"
      data-automation="splitViewParentWrapper"
      className={styles.splitViewWrapper}
    >
      <Columns collapseBelow="desktop" space={outerSpacing}>
        <Column width="2/5">
          <Box
            style={{ minHeight: 1400 }}
            position="relative"
            display="flex"
            flexDirection="column"
            paddingBottom={outerSpacing}
          >
            {!hideResults && shouldRenderRelatedSearch && (
              <Box
                component="section"
                paddingTop={LIST_FOOTER_ITEM_SPACING}
                data-automation="related-searches-splitview"
                className={styles.relatedSearches}
              >
                <RelatedSearches filterInfo={relatedList} />
              </Box>
            )}
            <Box className={shouldHavePagination && styles.jobListWrapper}>
              {ListView}
            </Box>
            {<Box width="full" flexGrow={1} />}

            {hideResults ? null : <Footer />}
          </Box>
        </Column>
        <Column>
          <Box
            background={shouldShowInitState ? undefined : 'surface'}
            height="full"
            display={['none', 'none', 'block']}
            className={styles.jobDetailsWrapper}
          >
            <SearchResultsHeaderSpacer />
            <Box
              data-automation="splitViewJobDetailsWrapper"
              height="full"
              background="surface"
              marginBottom={shouldShowInitState ? outerSpacing : 'none'}
              className={[
                {
                  [styles.jobDetailsIndependentScrollSession]: !(
                    refineBarV2 || stickySearchBar
                  ),
                  [styles.jobDetailsIndependentScrollSessionWithDynamicSearchBar]:
                    refineBarV2 || stickySearchBar,
                  [styles.makeScrollable]: !isLoading,
                  [styles.disableScroll]: isLoading,
                },
              ]}
              style={{
                top:
                  (refineBarV2 || stickySearchBar) &&
                  dynamicSearchBarType === 'minimised'
                    ? 88
                    : 0,
              }}
              ref={jobDetailsPaneElement}
              paddingBottom={outerSpacing}
            >
              {DetailView}
            </Box>
          </Box>
        </Column>
      </Columns>
    </Box>
  );
};
export default SplitViewLayout;
