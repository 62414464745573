import { metrics } from '@seek/metrics-js';
import { getSolSearchDataProp } from '@seek/sol-js';
import { useTranslations } from '@vocab/react';
import { Box, PageBlock } from 'braid-design-system';
import clsx from 'clsx';
import { Fragment, useEffect } from 'react';

import StickySerpTarget from 'src/components/StickySerpTarget/StickySerpTarget';
import { HomePage } from 'src/handlers/Home';
import { JobDetailsPageView } from 'src/handlers/JobDetails';
import {
  PageTitle,
  useSetHubblePage,
  useSetHubbleTags,
} from 'src/modules/hubble';
import { shouldRenderPOEA } from 'src/modules/seo-header/search-results-page/utils';
import { useSelector } from 'src/store/react';
import {
  selectCompanySuggestion,
  selectIsRadialFilterShown,
  selectIsResultsHidden,
  selectIsResultsInError,
  selectIsResultsLoading,
  selectResults,
  selectSolMetadataString,
  selectUserTestHeaders,
} from 'src/store/selectors';
import { useMelwaysZone } from 'src/utils/melwaysHelper';

import POEAHeading from '../Poea/PoeaHeading';

import translations from './.vocab';
import ResultsError from './ResultsError/ResultsError';
import SearchResultsHeaders from './SearchResultHeaders/SearchResultsHeaders';
import SeoFooter from './SeoFooter/SeoFooter';
import { SkyScraperBanner } from './SplitView/SkyScraperBanner/SkyScraperBanner';
import SplitView from './SplitView/SplitView';

import * as styles from './SearchResultsPage.css';

const getRootClasses = (hideResults: boolean) =>
  clsx({
    [styles.root]: true,
    [styles.root_areResultsHidden]: hideResults,
  });

const SearchResultPage = () => {
  useSetHubblePage(PageTitle.searchResults);
  const testHeaders = useSelector(selectUserTestHeaders);
  useSetHubbleTags(testHeaders);

  const { t } = useTranslations(translations);
  const searchResultsViewModel = useSelector(selectResults);
  const isLoading = useSelector(selectIsResultsLoading);
  const resultsHidden = useSelector(selectIsResultsHidden);
  const companySuggestion = useSelector(selectCompanySuggestion);
  const solMetadataString = useSelector(selectSolMetadataString) || '';
  const isError = useSelector(selectIsResultsInError);
  const shouldShowDistanceFrom = useSelector(selectIsRadialFilterShown);
  const {
    isZeroResults = true,
    searchParams,
    jobs,
  } = searchResultsViewModel || {};
  const { header, hasHeaderBeforeJobs, locationSuggestions } =
    searchResultsViewModel || {};

  const shouldShowDistanceHeader =
    hasHeaderBeforeJobs || shouldShowDistanceFrom;

  const zone = useMelwaysZone();

  useEffect(() => {
    JobDetailsPageView.preload();
    HomePage.preload();
  }, []);

  const renderPOEA = () =>
    shouldRenderPOEA({ zone, keywords: searchParams?.keywords });

  const renderSplitView = () => (
    <Fragment>
      <Box data-automation="searchResults" height="full">
        {renderPOEA() && (
          <POEAHeading
            keyword={searchParams?.keywords || ''}
            location={searchParams?.where}
          />
        )}
        <SplitView
          companySuggestion={companySuggestion}
          isZeroResults={isZeroResults}
          jobs={isLoading ? [] : jobs || []}
          resultSummary={
            <SearchResultsHeaders
              {...{
                shouldShowDistanceHeader,
                header,
                locationSuggestions,
                isZeroResults,
              }}
            />
          }
          isCompanySearch={Boolean(searchParams && searchParams.companysearch)}
        />
      </Box>
    </Fragment>
  );

  useEffect(() => {
    if (isError) {
      metrics.count('ui_error', [`type:search_results`]);
    }
  }, [isError]);

  if (resultsHidden) {
    return (
      <section className={getRootClasses(resultsHidden)}>
        <span
          data-automation="hiddenSearchResults"
          {...getSolSearchDataProp(solMetadataString)}
        />
      </section>
    );
  }

  const pageBlockWidth = 'large';

  return (
    <StickySerpTarget
      component="section"
      role="region"
      aria-label={t('Search Results')}
      className={getRootClasses(isLoading)}
      name="sticky-save-search-desktop"
    >
      {/* SeoFooter needs to be high in the DOM for google */}
      <PageBlock width="large">
        <SeoFooter key="seo-footer" />
      </PageBlock>

      {isError ? (
        <PageBlock width={pageBlockWidth}>
          <Box display="flex">
            <Box width="full" className={styles.content} paddingTop="xxlarge">
              <ResultsError />
            </Box>
          </Box>
        </PageBlock>
      ) : (
        <PageBlock width={pageBlockWidth}>
          <Box position="relative">
            {renderSplitView()}
            <SkyScraperBanner />
          </Box>
        </PageBlock>
      )}
    </StickySerpTarget>
  );
};
export default SearchResultPage;
