import type { SearchResultJobV5 } from '@seek/chalice-types';
import { Box, Loader, Stack } from 'braid-design-system';
import { type ComponentProps, memo, type ReactNode, useEffect } from 'react';

import { useSearchPath } from 'src/components/Search/SearchBar/SearchBar';
import { AccessabilityLinks } from 'src/components/SearchResultPage/SplitView/AccessabilityLinks/AccessabilityLinks';
import { useSplitViewContext } from 'src/handlers/SplitViewContext';
import useAuthenticated, {
  type AuthenticatedType,
} from 'src/hooks/useAuthenticated';
import { selectIsHomePage, selectTags } from 'src/store/location/selectors';
import { useDispatch, useSelector } from 'src/store/react';
import type { SearchViewModelCompanySuggestion } from 'src/store/results/types';
import { useRunSearch } from 'src/store/search/useRunSearch';
import {
  selectFeatureFlag,
  selectIsResultsLoading,
  selectLocationPageNumber,
  selectPaginationTotalPages,
} from 'src/store/selectors';

import NewToYouSignIn from '../NewToYouSignIn/NewToYouSignIn';
import SearchResultList from '../SearchResultList/SearchResultList';
import { UpToDateLastPage } from '../UpToDateLastPage/UpToDateLastPage';
import UpToDateZeroCount from '../UpToDateZeroCount/UpToDateZeroCount';
import ZeroResults from '../ZeroResults/ZeroResults';

import JobDetail from './JobDetail/JobDetail';
import SplitViewLayout from './SplitViewLayout/SplitViewLayout';
import { useShowResultSummaryWithBehaviouralCuesFiltersEnabled } from './useShowResultSummaryWithBehaviouralCuesFiltersEnabled/useShowResultSummaryWithBehaviouralCuesFiltersEnabled';
import useSplitViewOpen1stJobAdEffect from './useSplitViewOpen1stJobAdEffect/useSplitViewOpen1stJobAdEffect';

import * as styles from './SplitView.css';

export interface SplitViewProps {
  jobs: SearchResultJobV5[];
  resultSummary: ReactNode;
  isCompanySearch: ComponentProps<typeof SearchResultList>['isCompanySearch'];
  companySuggestion?: SearchViewModelCompanySuggestion;
  isZeroResults: boolean;
}

const BehaviouralCuesFiltersMessage = memo(
  ({
    authenticateState,
    isNewToYou,
    isZeroResults,
    companySuggestion,
  }: {
    authenticateState: AuthenticatedType;
    isNewToYou: boolean;
    isZeroResults: boolean;
    companySuggestion?: SearchViewModelCompanySuggestion;
  }) => {
    let message;

    if (isNewToYou) {
      if (authenticateState === 'unauthenticated') message = <NewToYouSignIn />;

      if (authenticateState === 'authenticated' && isZeroResults)
        message = <UpToDateZeroCount />;
    } else if (isZeroResults) {
      message = <ZeroResults companySuggestion={companySuggestion} />;
    }

    return message ? (
      <Box className={styles.LoadingWrapItem} zIndex={1} marginTop="xxxlarge">
        <Box
          data-automation="behavioural-cues-filters-message"
          width="full"
          className={styles.content}
        >
          {message}
        </Box>
      </Box>
    ) : null;
  },
);

const SrpLoader = () => (
  <Box className={styles.LoadingWrapItem} zIndex={1} pointerEvents="none">
    <Box width="full" className={styles.content}>
      <Box
        paddingY="xlarge"
        style={{ height: 400 }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        data-automation="search-results-loader"
      >
        <Loader size="large" />
      </Box>
    </Box>
  </Box>
);

const SplitView = ({
  jobs,
  resultSummary,
  isCompanySearch,
  companySuggestion,
  isZeroResults = false,
}: SplitViewProps) => {
  const {
    selectedJob,
    setSelectedJob,
    currentJobDetails,
    fetchJobDetails,
    isLoading,
  } = useSplitViewContext();

  const isSearchLoading = useSelector(selectIsResultsLoading);
  const totalPages = useSelector(selectPaginationTotalPages);
  const currentPageNumber = useSelector(selectLocationPageNumber);
  const searchTags = useSelector(selectTags);
  const isBehaviouralCuesFiltersEnabled = useSelector(
    selectFeatureFlag('behaviouralCuesFilters'),
  );
  const authenticateState = useAuthenticated();
  const isNewToYou = searchTags === 'new';

  useSplitViewOpen1stJobAdEffect(jobs);

  // SSA we need to check for count, if it's null it means we haven't done
  // a search on the server side - probably because we are searching for
  // a 'new' tag. This is to prevent first search results under 'new to you'
  // to appear before client side auth steps in and removes all the results
  // that the user had already seen.
  const newToYouCount = useSelector((c) => c.results.totalCountNewToYou) ?? -1;
  const dispatch = useDispatch();
  const runSearch = useRunSearch();
  const { currentSearchPath } = useSearchPath();
  useEffect(() => {
    if (
      !isLoading &&
      isNewToYou &&
      (newToYouCount === null || newToYouCount < 0)
    ) {
      dispatch(
        runSearch({
          pathname: currentSearchPath,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newToYouCount, isLoading, currentSearchPath, dispatch]);

  const isHomePage = useSelector(selectIsHomePage);
  const hideResults = isSearchLoading || isZeroResults;

  const showResultSummaryWithBehaviouralCuesFiltersEnabled =
    useShowResultSummaryWithBehaviouralCuesFiltersEnabled();

  return (
    <Box data-automation="split-view" className={styles.LoadingWrap}>
      {isSearchLoading && <SrpLoader />}

      {isBehaviouralCuesFiltersEnabled && (
        <>
          {!isSearchLoading && (
            <BehaviouralCuesFiltersMessage
              authenticateState={authenticateState}
              isNewToYou={isNewToYou}
              isZeroResults={isZeroResults}
              companySuggestion={companySuggestion}
            />
          )}

          <Box className={styles.LoadingWrapItem}>
            <SplitViewLayout
              ListView={
                <>
                  {showResultSummaryWithBehaviouralCuesFiltersEnabled
                    ? resultSummary
                    : null}
                  {hideResults ? null : (
                    <>
                      <Stack space="medium">
                        {jobs.length > 0 && (
                          <SearchResultList
                            jobs={jobs}
                            isCompanySearch={isCompanySearch}
                            setCurrentJob={setSelectedJob}
                            selectedJobId={selectedJob?.id}
                          />
                        )}
                        {currentPageNumber === totalPages && isNewToYou && (
                          <UpToDateLastPage />
                        )}
                      </Stack>
                    </>
                  )}
                </>
              }
              DetailView={
                hideResults ? null : (
                  <>
                    <AccessabilityLinks
                      placement="job-details"
                      position="top"
                    />
                    <JobDetail
                      currentJobDetails={selectedJob ? currentJobDetails : null}
                      fetchJobDetails={fetchJobDetails}
                      isLoading={isLoading}
                    />
                    {selectedJob ? (
                      <AccessabilityLinks
                        placement="job-details"
                        position="bottom"
                      />
                    ) : null}
                  </>
                )
              }
            />
          </Box>
        </>
      )}

      {!isBehaviouralCuesFiltersEnabled && (
        <>
          {!isSearchLoading && isZeroResults && (
            <Box
              className={styles.LoadingWrapItem}
              zIndex={1}
              marginTop="xxxlarge"
            >
              <Box width="full" className={styles.content}>
                {!isHomePage && (
                  <ZeroResults companySuggestion={companySuggestion} />
                )}
              </Box>
            </Box>
          )}

          {!isSearchLoading && !isZeroResults && (
            <Box className={styles.LoadingWrapItem}>
              <SplitViewLayout
                ListView={
                  <>
                    {resultSummary}
                    {hideResults ? null : (
                      <Stack space="medium">
                        {jobs.length > 0 && (
                          <SearchResultList
                            jobs={jobs}
                            isCompanySearch={isCompanySearch}
                            setCurrentJob={setSelectedJob}
                            selectedJobId={selectedJob?.id}
                          />
                        )}
                        {currentPageNumber === totalPages && isNewToYou && (
                          <UpToDateLastPage />
                        )}
                      </Stack>
                    )}
                  </>
                }
                DetailView={
                  hideResults ? null : (
                    <>
                      <AccessabilityLinks
                        placement="job-details"
                        position="top"
                      />
                      <JobDetail
                        currentJobDetails={
                          selectedJob ? currentJobDetails : null
                        }
                        fetchJobDetails={fetchJobDetails}
                        isLoading={isLoading}
                      />
                      {selectedJob ? (
                        <AccessabilityLinks
                          placement="job-details"
                          position="bottom"
                        />
                      ) : null}
                    </>
                  )
                }
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default SplitView;
