import {
  type JobDetailsView,
  useJobDetailsProps,
} from '@seek/job-details-view';
import { useMemo } from 'react';

import { useJDVSkillsMatchProps } from 'src/components/JobDetailsPage/useJDVSkillsMatchProps';
import { useSkillsMatchTracking } from 'src/components/JobDetailsPage/useSkillsMatchTracking';
import { useSplitViewContext } from 'src/handlers/SplitViewContext';
import { useIsPDPAConsentRequired } from 'src/hooks/useIsPDPAConsentRequired';
import { useReturnUrl } from 'src/hooks/useReturnUrl';
import {
  useSaveJobJDV,
  type UseSaveJobJDVProps,
} from 'src/hooks/useSaveJob/useSaveJobJDV';
import { useSolHash } from 'src/hooks/useSolHash';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useCandidateDetails } from 'src/modules/hooks/useCandidateDetailsQuery';
import type { JobDetails } from 'src/store/jobdetails/types';
import { useSelector } from 'src/store/react';
import {
  selectAuthenticated,
  selectFeatureFlag,
  selectIsJDP,
  selectJobDetailsPersonalised,
  selectResults,
} from 'src/store/selectors';

import buildJobDetailsSEOLinks from './buildJobDetailsSEOLinks';
import { useApplyTracking } from './useApplyTracking';
import useJDVTracking from './useJDVTracking';
import { useJobDetailsSolMetadataReference } from './useJobDetailsSolMetadataReference';
import useReportJobAd from './useReportJobAd';

interface UsePropsForJobDetailsViewProps {
  jobDetails: JobDetails;
  options?: {
    origin?: string;
    position?: number;
    solHash?: string;
  };
  savedJobMutation?: UseSaveJobJDVProps['savedJobMutation'];
}

const useMapJobIdToJobDisplayType = (jobId: string) => {
  const jobs = useSelector(selectResults)?.jobs;
  return jobs?.find((job) => job.id === jobId)?.displayType;
};

export const usePropsForJobDetailsView = ({
  jobDetails,
  options: { origin: jobOrigin, position: jobPosition, solHash } = {},
  savedJobMutation,
}: UsePropsForJobDetailsViewProps): React.ComponentProps<
  typeof JobDetailsView
> => {
  const jobId = jobDetails.job.id;
  const { email } = useCandidateDetails();
  const isAuthenticated = useSelector(selectAuthenticated);
  const jobDetailsPersonalisedFromRedux = useSelector(
    selectJobDetailsPersonalised,
  );
  const jobDetailsPersonalised =
    jobDetails.personalised || jobDetailsPersonalisedFromRedux;
  const enableMatchedQualities = useSelector(
    selectFeatureFlag('matchedQualities'),
  );

  const isPDPAConsentRequired =
    useIsPDPAConsentRequired().isPDPAConsentRequired;
  const solMetadataReference =
    useJobDetailsSolMetadataReference(jobDetails) || solHash;

  const [, solMetadata] = useSolHash({
    id: jobId,
    solMetadataReference,
  });

  const displayType = useMapJobIdToJobDisplayType(jobId);

  const onAnalyticsEvent = useJDVTracking();
  const onReportJobAd = useReportJobAd(jobDetails?.job?.id);

  const registerUrl = useReturnUrl({ mode: 'register' });
  const signInUrl = useReturnUrl({ mode: 'signin' });

  const isHirerVerified = jobDetails?.job?.advertiser?.isVerified ?? false;

  const qualities = useJDVSkillsMatchProps(jobId);

  useSkillsMatchTracking({ qualities, jobId, solMetadata });

  const isMatchedQualitiesEnabled = useSelector(
    selectFeatureFlag('matchedQualities'),
  );

  const [applyUrl, onApplyClick] = useApplyTracking({
    jobDetails,
    jobDetailsPersonalised,
    solMetadataReference,
    isHirerVerified,
    isMatchedSkillsWidgetShown: qualities.shouldShowSkillsMatch,
    isMatchedSkillsNudgeShown: enableMatchedQualities && !isAuthenticated,
    jobOrigin,
  });

  const analyticsFacade = useAnalyticsFacade();
  const { selectedJobPosition } = useSplitViewContext();

  const trackVerifiedHirerBadgeClick = (linkSection: string) => {
    analyticsFacade.verifiedHirerBadgeClicked({
      jobId,
      jobListingPosition: jobPosition || selectedJobPosition || -1, // -1 is the default value when job is in standalone view
      linkSection,
    });
  };

  const trackMoreAboutThisCompanyClick = (linkSection: string) => {
    analyticsFacade.companyProfileWidgetLinkClicked({
      linkSection,
    });
  };

  const { toggleSavedJob, isSaved } = useSaveJobJDV({
    jobId: jobDetails?.job?.id,
    solMetadataReference,
    savedJobMutation,
  });

  const result = useJobDetailsProps({
    flags: {
      isSaved:
        jobDetailsPersonalised?.isSaved === isSaved
          ? jobDetailsPersonalised.isSaved
          : isSaved,
      appliedDateFormatted:
        jobDetailsPersonalised?.appliedDateTime?.shortAbsoluteLabel,
      isAuthenticated,
    },
    topApplicant: jobDetailsPersonalised?.topApplicantBadge,
    jobApplyUrl: applyUrl,
    jobDetails,
    salaryMatch: jobDetailsPersonalised?.salaryMatch,
  });

  const isJobDetailsStandalone = useSelector(selectIsJDP);

  const {
    classification,
    normalisedRoleTitle,
    subClassification,
    where,
    workType,
  } = jobDetails.seoInfo || {};

  const seoInfo = useMemo(
    () =>
      buildJobDetailsSEOLinks({
        normalisedRoleTitle,
        where,
        workType,
        classification,
        subClassification,
      }),
    [classification, subClassification, where, workType, normalisedRoleTitle],
  );

  return {
    isPDPAConsentRequired,
    job: result.job,
    ctas: {
      onApplyClick,
      onToggleSaveJob: toggleSavedJob,
      onReportJobAd,
      trackVerifiedHirerBadgeClick,
      trackMoreAboutThisCompanyClick,
    },
    reportJobAdInitialValues: {
      email,
      reason: '',
      comment: '',
    },
    onAnalyticsEvent,
    uniqueContainerValue: 'job-details-page',
    shouldOpenApplyInNewTab: !isJobDetailsStandalone,
    // This should be removed in metropolis after
    // https://myseek.atlassian.net/browse/DCS-9549
    hideOpenInNewTab: true,
    displayCompanyProfile: true,
    renderRelativePaths: true,
    jobUrlOpts: {
      hide: isJobDetailsStandalone,
      params: {
        ref: 'search-standalone',
        type: displayType,
        origin: true,
      },
      meta: {
        solMetadataReference,
      },
    },
    registerUrl,
    signInUrl,
    isLoggedIn: isAuthenticated,
    qualities: enableMatchedQualities ? qualities : undefined,
    featureFlags: {
      enableVerifiedHirerBadge: true,
      enableReducedDuplicatedApply: true,
      stickApplyButtonOnDesktop: true,
      enableMatchedQualities: isMatchedQualitiesEnabled,
      hideApplyButtonOnPrivateAdvertiser: true,
    },
    seoInfo,
  };
};
