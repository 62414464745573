import { Box } from 'braid-design-system';

import useAuthenticated from 'src/hooks/useAuthenticated';
import { useSelector } from 'src/store/react';
import {
  selectPaginationTotalPages,
  selectResultsCount,
} from 'src/store/selectors';

import Pagination from '../../Pagination/Pagination';
import SaveSearchSection from '../../SaveSearchSection/SaveSearchSection';
import { MRECBanner } from '../MRECBanner/MRECBanner';

export const LIST_FOOTER_ITEM_SPACING = 'xlarge'; // listFooterItemSpacing = 'xlarge';

export const Footer = () => {
  const authenticateState = useAuthenticated();

  const totalPages = useSelector(selectPaginationTotalPages);
  const shouldHavePagination = totalPages > 1;

  const hideResults = useSelector(selectResultsCount) === 0;

  const shouldShowSaveSearchAbovePagination =
    authenticateState === 'authenticated';
  const shouldShowSaveSearchUnderPagination =
    authenticateState === 'unauthenticated';

  return (
    <>
      {shouldShowSaveSearchAbovePagination && (
        <SaveSearchSection spaceTop={LIST_FOOTER_ITEM_SPACING} />
      )}
      {shouldHavePagination && (
        <Box paddingTop={LIST_FOOTER_ITEM_SPACING}>
          <Pagination />
        </Box>
      )}
      {shouldShowSaveSearchUnderPagination && (
        <SaveSearchSection spaceTop={LIST_FOOTER_ITEM_SPACING} />
      )}
      <MRECBanner visible={!hideResults} />
    </>
  );
};
