
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiJOZXcgdG8geW91IiwiTmV3IjoiTmV3IiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IiwiQWxsIGpvYnMiOiJBbGwgam9icyIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7am9ic30gb25lIHtqb2J9IG90aGVyIHtqb2JzfX0iLCJBbGwge3RvdGFsQ291bnR9IGpvYnMiOiJ7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB7QWxsIGpvYnN9IG9uZSB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ifSBvdGhlciB7QWxsIHtkaXNwbGF5VG90YWxDb3VudH0gam9ic319In0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2xvd29uZ2FufSBvbmUge2xvd29uZ2FufSBvdGhlciB7bG93b25nYW59fXthdENvbXBhbnl9IiwiYXQge2NvbXBhbnl9IjoiZGkge2NvbXBhbnl9IiwiTmV3IHRvIHlvdSI6IkJhcnUgdW50dWsga2FtdSIsIk5ldyI6IkJhcnUiLCJKb2JzIHlvdSd2ZSBub3QgYnJvd3NlZCB5ZXQiOiJQZWtlcmphYW4geWFuZyBiZWx1bSBBbmRhIGplbGFqYWhpIiwiQWxsIGpvYnMiOiJTZW11YSBwZWtlcmphYW4iLCJ7dG90YWxDb3VudH0gam9icyI6IntkaXNwbGF5VG90YWxDb3VudH0ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3Bla2VyamFhbn0gb25lIHtwZWtlcmphYW59IG90aGVyIHtwZWtlcmphYW59fSIsIkFsbCB7dG90YWxDb3VudH0gam9icyI6Int0b3RhbENvdW50LCBwbHVyYWwsID0wIHtTZW11YSBwZWtlcmphYW59IG9uZSB7U2VtdWEge2Rpc3BsYXlUb3RhbENvdW50fSBwZWtlcmphYW59IG90aGVyIHtTZW11YSB7ZGlzcGxheVRvdGFsQ291bnR9IHBla2VyamFhbn19In0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2xvd29uZ2FufSBvbmUge2xvd29uZ2FufSBvdGhlciB7bG93b25nYW59fXthdENvbXBhbnl9IiwiYXQge2NvbXBhbnl9IjoiZGkge2NvbXBhbnl9IiwiTmV3IHRvIHlvdSI6IkJhcnUgdW50dWsga2FtdSIsIk5ldyI6IkJhcnUiLCJKb2JzIHlvdSd2ZSBub3QgYnJvd3NlZCB5ZXQiOiJQZWtlcmphYW4geWFuZyBiZWx1bSBBbmRhIGplbGFqYWhpIiwiQWxsIGpvYnMiOiJTZW11YSBwZWtlcmphYW4iLCJ7dG90YWxDb3VudH0gam9icyI6IntkaXNwbGF5VG90YWxDb3VudH0ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3Bla2VyamFhbn0gb25lIHtwZWtlcmphYW59IG90aGVyIHtwZWtlcmphYW59fSIsIkFsbCB7dG90YWxDb3VudH0gam9icyI6Int0b3RhbENvdW50LCBwbHVyYWwsID0wIHtTZW11YSBwZWtlcmphYW59IG9uZSB7U2VtdWEge2Rpc3BsYXlUb3RhbENvdW50fSBwZWtlcmphYW59IG90aGVyIHtTZW11YSB7ZGlzcGxheVRvdGFsQ291bnR9IHBla2VyamFhbn19In0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge%2BC4h%2BC4suC4mX0gb25lIHvguIfguLLguJl9IG90aGVyIHvguIfguLLguJl9fXthdENvbXBhbnl9IiwiYXQge2NvbXBhbnl9Ijoi4LiX4Li14LmIIHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiLguYPguKvguKHguYjguKrguLPguKvguKPguLHguJrguITguLjguJMiLCJOZXciOiLguYPguKvguKHguYgiLCJKb2JzIHlvdSd2ZSBub3QgYnJvd3NlZCB5ZXQiOiLguIfguLLguJnguJfguLXguYjguKLguLHguIfguYTguKHguYjguYTguJTguYnguYDguKPguLXguKLguIHguJTguLkiLCJBbGwgam9icyI6IuC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB74LiH4Liy4LiZfSBvbmUge%2BC4h%2BC4suC4mX0gb3RoZXIge%2BC4h%2BC4suC4mX19IiwiQWxsIHt0b3RhbENvdW50fSBqb2JzIjoie3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge%2BC4l%2BC4uOC4geC4h%2BC4suC4mX0gb25lIHvguJfguLHguYnguIfguKvguKHguJQge2Rpc3BsYXlUb3RhbENvdW50fSDguIfguLLguJl9IG90aGVyIHvguIfguLLguJkge2Rpc3BsYXlUb3RhbENvdW50fSDguJfguLHguYnguIfguKvguKHguJR9fSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge%2BC4h%2BC4suC4mX0gb25lIHvguIfguLLguJl9IG90aGVyIHvguIfguLLguJl9fXthdENvbXBhbnl9IiwiYXQge2NvbXBhbnl9Ijoi4LiX4Li14LmIIHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiLguYPguKvguKHguYjguKrguLPguKvguKPguLHguJrguITguLjguJMiLCJOZXciOiLguYPguKvguKHguYgiLCJKb2JzIHlvdSd2ZSBub3QgYnJvd3NlZCB5ZXQiOiLguIfguLLguJnguJfguLXguYjguKLguLHguIfguYTguKHguYjguYTguJTguYnguYDguKPguLXguKLguIHguJTguLkiLCJBbGwgam9icyI6IuC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB74LiH4Liy4LiZfSBvbmUge%2BC4h%2BC4suC4mX0gb3RoZXIge%2BC4h%2BC4suC4mX19IiwiQWxsIHt0b3RhbENvdW50fSBqb2JzIjoie3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge%2BC4l%2BC4uOC4geC4h%2BC4suC4mX0gb25lIHvguJfguLHguYnguIfguKvguKHguJQge2Rpc3BsYXlUb3RhbENvdW50fSDguIfguLLguJl9IG90aGVyIHvguIfguLLguJkge2Rpc3BsYXlUb3RhbENvdW50fSDguJfguLHguYnguIfguKvguKHguJR9fSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2xvd29uZ2FufSBvbmUge2xvd29uZ2FufSBvdGhlciB7bG93b25nYW59fXthdENvbXBhbnl9IiwiYXQge2NvbXBhbnl9IjoiZGkge2NvbXBhbnl9IiwiTmV3IHRvIHlvdSI6IkJhcnUgdW50dWsga2FtdSIsIk5ldyI6IkJhcnUiLCJKb2JzIHlvdSd2ZSBub3QgYnJvd3NlZCB5ZXQiOiJQZWtlcmphYW4geWFuZyBiZWx1bSBBbmRhIGplbGFqYWhpIiwiQWxsIGpvYnMiOiJTZW11YSBwZWtlcmphYW4iLCJ7dG90YWxDb3VudH0gam9icyI6IntkaXNwbGF5VG90YWxDb3VudH0ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3Bla2VyamFhbn0gb25lIHtwZWtlcmphYW59IG90aGVyIHtwZWtlcmphYW59fSIsIkFsbCB7dG90YWxDb3VudH0gam9icyI6Int0b3RhbENvdW50LCBwbHVyYWwsID0wIHtTZW11YSBwZWtlcmphYW59IG9uZSB7U2VtdWEge2Rpc3BsYXlUb3RhbENvdW50fSBwZWtlcmphYW59IG90aGVyIHtTZW11YSB7ZGlzcGxheVRvdGFsQ291bnR9IHBla2VyamFhbn19In0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2xvd29uZ2FufSBvbmUge2xvd29uZ2FufSBvdGhlciB7bG93b25nYW59fXthdENvbXBhbnl9IiwiYXQge2NvbXBhbnl9IjoiZGkge2NvbXBhbnl9IiwiTmV3IHRvIHlvdSI6IkJhcnUgdW50dWsga2FtdSIsIk5ldyI6IkJhcnUiLCJKb2JzIHlvdSd2ZSBub3QgYnJvd3NlZCB5ZXQiOiJQZWtlcmphYW4geWFuZyBiZWx1bSBBbmRhIGplbGFqYWhpIiwiQWxsIGpvYnMiOiJTZW11YSBwZWtlcmphYW4iLCJ7dG90YWxDb3VudH0gam9icyI6IntkaXNwbGF5VG90YWxDb3VudH0ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge3Bla2VyamFhbn0gb25lIHtwZWtlcmphYW59IG90aGVyIHtwZWtlcmphYW59fSIsIkFsbCB7dG90YWxDb3VudH0gam9icyI6Int0b3RhbENvdW50LCBwbHVyYWwsID0wIHtTZW11YSBwZWtlcmphYW59IG9uZSB7U2VtdWEge2Rpc3BsYXlUb3RhbENvdW50fSBwZWtlcmphYW59IG90aGVyIHtTZW11YSB7ZGlzcGxheVRvdGFsQ291bnR9IHBla2VyamFhbn19In0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge%2BC4h%2BC4suC4mX0gb25lIHvguIfguLLguJl9IG90aGVyIHvguIfguLLguJl9fXthdENvbXBhbnl9IiwiYXQge2NvbXBhbnl9Ijoi4LiX4Li14LmIIHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiLguYPguKvguKHguYjguKrguLPguKvguKPguLHguJrguITguLjguJMiLCJOZXciOiLguYPguKvguKHguYgiLCJKb2JzIHlvdSd2ZSBub3QgYnJvd3NlZCB5ZXQiOiLguIfguLLguJnguJfguLXguYjguKLguLHguIfguYTguKHguYjguYTguJTguYnguYDguKPguLXguKLguIHguJTguLkiLCJBbGwgam9icyI6IuC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB74LiH4Liy4LiZfSBvbmUge%2BC4h%2BC4suC4mX0gb3RoZXIge%2BC4h%2BC4suC4mX19IiwiQWxsIHt0b3RhbENvdW50fSBqb2JzIjoie3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge%2BC4l%2BC4uOC4geC4h%2BC4suC4mX0gb25lIHvguJfguLHguYnguIfguKvguKHguJQge2Rpc3BsYXlUb3RhbENvdW50fSDguIfguLLguJl9IG90aGVyIHvguIfguLLguJkge2Rpc3BsYXlUb3RhbENvdW50fSDguJfguLHguYnguIfguKvguKHguJR9fSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4%2Be2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge%2BC4h%2BC4suC4mX0gb25lIHvguIfguLLguJl9IG90aGVyIHvguIfguLLguJl9fXthdENvbXBhbnl9IiwiYXQge2NvbXBhbnl9Ijoi4LiX4Li14LmIIHtjb21wYW55fSIsIk5ldyB0byB5b3UiOiLguYPguKvguKHguYjguKrguLPguKvguKPguLHguJrguITguLjguJMiLCJOZXciOiLguYPguKvguKHguYgiLCJKb2JzIHlvdSd2ZSBub3QgYnJvd3NlZCB5ZXQiOiLguIfguLLguJnguJfguLXguYjguKLguLHguIfguYTguKHguYjguYTguJTguYnguYDguKPguLXguKLguIHguJTguLkiLCJBbGwgam9icyI6IuC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsInt0b3RhbENvdW50fSBqb2JzIjoie2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCwgcGx1cmFsLCA9MCB74LiH4Liy4LiZfSBvbmUge%2BC4h%2BC4suC4mX0gb3RoZXIge%2BC4h%2BC4suC4mX19IiwiQWxsIHt0b3RhbENvdW50fSBqb2JzIjoie3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge%2BC4l%2BC4uOC4geC4h%2BC4suC4mX0gb25lIHvguJfguLHguYnguIfguKvguKHguJQge2Rpc3BsYXlUb3RhbENvdW50fSDguIfguLLguJl9IG90aGVyIHvguIfguLLguJkge2Rpc3BsYXlUb3RhbENvdW50fSDguJfguLHguYnguIfguKvguKHguJR9fSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiWzxzcGFuPntkaXNwbGF5VG90YWxDb3VudH08L3NwYW4%2BIHt0b3RhbENvdW50LHBsdXJhbCw9MHvEtcO2w7bDtsOffSBvbmV7xLXDtsO2w7bDn30gb3RoZXJ7xLXDtsO2w7bDn8WhfX17YXRDb21wYW55fV0iLCJhdCB7Y29tcGFueX0iOiJbxIPEg8SD4bmvIHtjb21wYW55fV0iLCJOZXcgdG8geW91IjoiW8OR4bq94bq9xbUg4bmvw7bDtiDDvcO9w7bDtseax5pdIiwiTmV3IjoiW8OR4bq94bq94bq9xbVdIiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiW8S0w7bDtsO2w5%2FFoSDDvcO9w73DtsO2w7bHmseax5on4bm94bq94bq94bq9IOC4geC4teC5icO2w7bDtuG5ryDDn8WZw7bDtsO2xbXFoeG6veG6veG6vcaMIMO9w73DveG6veG6veG6veG5r10iLCJBbGwgam9icyI6IlvhuqzGmsaaIMS1w7bDtsO2w7bDtsOfxaFdIiwie3RvdGFsQ291bnR9IGpvYnMiOiJbe2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCxwbHVyYWwsPTB7xLXDtsO2w7bDn8WhfSBvbmV7xLXDtsO2w7bDn30gb3RoZXJ7xLXDtsO2w7bDn8WhfX1dIiwiQWxsIHt0b3RhbENvdW50fSBqb2JzIjoiW3t0b3RhbENvdW50LHBsdXJhbCw9MHvhuqzGmsaaIMS1w7bDtsO2w7bDtsOfxaF9IG9uZXvhuqzGmsaaIHtkaXNwbGF5VG90YWxDb3VudH0gxLXDtsO2w7bDn30gb3RoZXJ74bqsxprGmiB7ZGlzcGxheVRvdGFsQ291bnR9IMS1w7bDtsO2w5%2FFoX19XSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiWzxzcGFuPntkaXNwbGF5VG90YWxDb3VudH08L3NwYW4%2BIHt0b3RhbENvdW50LHBsdXJhbCw9MHvEtcO2w7bDtsOffSBvbmV7xLXDtsO2w7bDn30gb3RoZXJ7xLXDtsO2w7bDn8WhfX17YXRDb21wYW55fV0iLCJhdCB7Y29tcGFueX0iOiJbxIPEg8SD4bmvIHtjb21wYW55fV0iLCJOZXcgdG8geW91IjoiW8OR4bq94bq9xbUg4bmvw7bDtiDDvcO9w7bDtseax5pdIiwiTmV3IjoiW8OR4bq94bq94bq9xbVdIiwiSm9icyB5b3UndmUgbm90IGJyb3dzZWQgeWV0IjoiW8S0w7bDtsO2w5%2FFoSDDvcO9w73DtsO2w7bHmseax5on4bm94bq94bq94bq9IOC4geC4teC5icO2w7bDtuG5ryDDn8WZw7bDtsO2xbXFoeG6veG6veG6vcaMIMO9w73DveG6veG6veG6veG5r10iLCJBbGwgam9icyI6IlvhuqzGmsaaIMS1w7bDtsO2w7bDtsOfxaFdIiwie3RvdGFsQ291bnR9IGpvYnMiOiJbe2Rpc3BsYXlUb3RhbENvdW50fSB7dG90YWxDb3VudCxwbHVyYWwsPTB7xLXDtsO2w7bDn8WhfSBvbmV7xLXDtsO2w7bDn30gb3RoZXJ7xLXDtsO2w7bDn8WhfX1dIiwiQWxsIHt0b3RhbENvdW50fSBqb2JzIjoiW3t0b3RhbENvdW50LHBsdXJhbCw9MHvhuqzGmsaaIMS1w7bDtsO2w7bDtsOfxaF9IG9uZXvhuqzGmsaaIHtkaXNwbGF5VG90YWxDb3VudH0gxLXDtsO2w7bDn30gb3RoZXJ74bqsxprGmiB7ZGlzcGxheVRvdGFsQ291bnR9IMS1w7bDtsO2w5%2FFoX19XSJ9!"
        )
      )
      });
  
      export { translations as default };
    