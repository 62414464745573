interface GetNewToYouBadgeContentProps {
  authenticated: boolean;
  newToYouTotal: number;
}

export const getNewToYouBadgeContent = ({
  authenticated,
  newToYouTotal,
}: GetNewToYouBadgeContentProps) => {
  if (authenticated && newToYouTotal > -1) {
    if (newToYouTotal === 0) return '0';
    else if (newToYouTotal > 99) return '99+';
    else if (newToYouTotal > 0) return newToYouTotal?.toString();
  }
  return null;
};
