import { useQuery } from '@apollo/client';

import type { CompetitiveJobType } from 'src/components/JobDetailsPage/useCompetitiveJobs';
import { SearchSavedAndAppliedJobsDocument } from 'src/graphql/graphql';
import { getUseUpdateSearchSavedAndAppliedJobsCache } from 'src/hooks/useSaveJob/utils/useSavedJobsData';
import { useToggleSavedJob } from 'src/hooks/useSaveJob/utils/useToggleSavedJob';
import { useSelector } from 'src/store/react';
import { selectAuthenticated } from 'src/store/user/selectors';

export const useSavedJobsDataCP = ({
  jobIds,
  shouldSkip = false,
}: {
  jobIds: string[];
  shouldSkip?: boolean;
}) => {
  const isAuthenticated = useSelector(selectAuthenticated);

  const searchSavedAndAppliedJob = useQuery(SearchSavedAndAppliedJobsDocument, {
    skip: !Boolean(jobIds?.length) || shouldSkip || !isAuthenticated,
    variables: { jobIds },
    ssr: false,
  });

  return searchSavedAndAppliedJob;
};

const getTrackingData = (jobs: CompetitiveJobType[]) => {
  const solHashMap: Record<
    string,
    Parameters<typeof useToggleSavedJob>[0]['solMetadataReference']
  > = {};
  const jobIds: string[] = [];

  jobs.forEach(({ id, solHash }) => {
    jobIds.push(id);
    solHashMap[id] = solHash;
  });

  return { jobIds, solHashMap };
};

export const getUseSaveJobCompetitiveJob = (jobs: CompetitiveJobType[]) => {
  const { jobIds, solHashMap } = getTrackingData(jobs);

  const useUpdateCPSavedAndAppliedJobsCache =
    getUseUpdateSearchSavedAndAppliedJobsCache(jobIds);

  return ({ jobId }: { jobId: string }) => {
    const savedJobsData = useSavedJobsDataCP({ jobIds });
    const { setSaved, setUnsaved } = useUpdateCPSavedAndAppliedJobsCache(jobId);
    const savedJobMutation = {
      create: { update: setSaved },
      delete: { update: setUnsaved },
    };

    const solMetadataReference = solHashMap[jobId];

    const { isSaved, isApplied, toggleSavedJob } = useToggleSavedJob({
      jobId,
      view: 'jobDetails',
      linkPosition: 'listing',
      savedJobsData: savedJobsData?.data,
      savedJobMutation,
      currentPageSection: 'competitive recommended jobs',
      solMetadataReference,
    });

    return {
      isSaved,
      toggleSavedJob,
      isApplied,
    };
  };
};
