import { useHubble } from '@seek/hubble';
import type { JobDetailsView } from '@seek/job-details-view';
import type { SolMetadata } from '@seek/sol-js';
import { useQuery } from 'node_modules/@apollo/client';
import { type ComponentProps, useEffect, useState } from 'react';

import { getSerpLogoFromBrandId } from 'src/components/JobDetailsPage/getSerpLogoLinkFromBrandId';
import {
  DateTimeContext,
  DateTimeLength,
  JobDetailsRecommendedJobsDocument,
  Platform,
} from 'src/graphql/graphql';
import { useTimezone } from 'src/hooks/useTimezone';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useBatchSolMetadataReference } from 'src/modules/hooks/useSolMetadataReference';
import { useDispatch, useSelector } from 'src/store/react';
import { saveSolReferencesForRecsAction } from 'src/store/results';
import { selectFeatureFlag, selectUserClientId } from 'src/store/selectors';
import { useMelwaysLocale, useMelwaysZone } from 'src/utils/melwaysHelper';

export type CompetitiveJobType = NonNullable<
  ComponentProps<typeof JobDetailsView>['competitiveJobs']
>[number];

const useCompetitiveJobTracking = (solMetadata?: SolMetadata) => {
  const [isTrackSent, setIsTrackingSent] = useState(false);
  const analyticsFacade = useAnalyticsFacade();
  const hubble = useHubble();
  useEffect(() => {
    if (solMetadata && !isTrackSent) {
      hubble.trackEvent('recommended_jobs_displayed', { solMetadata });
      setIsTrackingSent(true);
    }
  }, [analyticsFacade, isTrackSent, solMetadata, setIsTrackingSent, hubble]);
};

export const useCompetitiveJobs = (
  jobDetailsId: string,
  shouldSkip: boolean = false,
): CompetitiveJobType[] => {
  const dispatch = useDispatch();
  const skip =
    !useSelector(selectFeatureFlag('competitivePlacement')) ||
    shouldSkip ||
    !ENV.CLIENT;

  const { data, error, loading } = useQuery(JobDetailsRecommendedJobsDocument, {
    context: {
      headers: {
        // TODO - clean up when feature flag COMPETITIVE_PLACEMENT
        'x-custom-features': 'application/features.seek.all+json',
      },
    },
    variables: {
      jobDetailsId,
      zone: useMelwaysZone(),
      platform: Platform.Web,
      visitorId: useSelector(selectUserClientId),
      context: DateTimeContext.JobPosted,
      length: DateTimeLength.Short,
      timezone: useTimezone(),
      locale: useMelwaysLocale(),
    },
    skip,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [cpSolMetadata, setCpSolMetadata] = useState<SolMetadata>();
  useEffect(() => {
    const recs = data?.jobDetails?.recommendedJobs;

    if (recs) {
      if (recs[0]?.solMetadata) {
        setCpSolMetadata(recs[0].solMetadata);
      }

      dispatch(
        saveSolReferencesForRecsAction(
          recs.map((rec) => ({
            job: { id: rec.job?.id ?? '' },
            solMetadata: rec.solMetadata,
          })),
        ),
      );
    }
  }, [data, dispatch]);

  const solHashList = useBatchSolMetadataReference(
    data?.jobDetails?.recommendedJobs?.map((rec) => ({
      jobId: rec.job?.id ?? '',
      isFeaturedOrDisplayKeyType: 'competitivePlacement',
    })),
  );

  useCompetitiveJobTracking(cpSolMetadata);

  if (!skip && !loading && !error && data) {
    const competitiveJobs: CompetitiveJobType[] = [];
    data.jobDetails?.recommendedJobs?.forEach(({ job, solMetadata }, index) => {
      if (job) {
        const { id, title, advertiser, location, listedAt, products } = job;
        const { branding } = products ?? {};
        const cpJob: CompetitiveJobType = {
          solMetadata,
          solHash: solHashList[index],
          company: {
            name: advertiser.name,
          },
          title,
          id,
          location: location.label,
          postedDateFormatted: listedAt?.label ?? '...',
          srcLogo: branding?.id
            ? getSerpLogoFromBrandId(branding.id)
            : undefined,
        };

        competitiveJobs.push(cpJob);
      }
    });

    return competitiveJobs;
  }
  return [];
};
