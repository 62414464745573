import { selectTags } from 'src/store/location/selectors';
import { useSelector } from 'src/store/react';
import { selectFeatureFlag, selectResultsCount } from 'src/store/selectors';

export const useShowResultSummaryWithBehaviouralCuesFiltersEnabled = () => {
  const searchTags = useSelector(selectTags);
  const isNewToYou = searchTags === 'new';
  const isAllTabSearchResultsCountZero = useSelector(selectResultsCount) === 0;
  const isBehaviouralCuesFiltersEnabled = useSelector(
    selectFeatureFlag('behaviouralCuesFilters'),
  );

  if (!isBehaviouralCuesFiltersEnabled) {
    return false;
  }

  return !isAllTabSearchResultsCountZero || isNewToYou;
};
