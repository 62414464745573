import { metrics } from '@seek/metrics-js';
import {
  Box,
  Column,
  Columns,
  IconClear,
  Inline,
  Stack,
  Text,
} from 'braid-design-system';
import {
  useCallback,
  useEffect,
  useState,
  type ComponentProps,
  type ComponentType,
} from 'react';
import { useInView } from 'react-intersection-observer';

import AnimatedHeight from 'src/components/AnimatedHeight/AnimatedHeight';
import {
  isBrowserAnalyticsFacade,
  type NudgeCTAProps,
  type NudgeType,
  useAnalyticsFacade,
} from 'src/modules/AnalyticsFacade';
import { isMobileUserAgent } from 'src/modules/chalice-user-agent/device-detect';
import { useCandidateDetails } from 'src/modules/hooks/useCandidateDetailsQuery';
import { useSelector } from 'src/store/react';
import { selectSessionId, selectUserAgent } from 'src/store/selectors';

export type NudgeCTA = NudgeCTAProps & {
  Cta: ComponentType;
};

interface NudgeTemplateProps {
  CTAs: NudgeCTA[];
  data?: ComponentProps<typeof Box>['data'];
  errorNudgeType?: NudgeType;
  heading?: string;
  Icon?: ComponentType;
  id: string | number;
  nudgeType: NudgeType;
  onDismiss?: () => void;
  position: number;
  shouldFireMetrics?: boolean;
  shouldImpressNudge?: boolean;
  showIconInMobile?: boolean;
  subHeading?: string;
  text?: string;
}

export const NudgeTemplate = ({
  CTAs,
  data,
  errorNudgeType,
  heading,
  Icon,
  id,
  nudgeType,
  onDismiss,
  position,
  shouldFireMetrics,
  shouldImpressNudge,
  showIconInMobile,
  subHeading,
  text,
}: NudgeTemplateProps) => {
  const analyticsFacade = useAnalyticsFacade();
  const sessionId = useSelector(selectSessionId);
  const { id: seekerId } = useCandidateDetails();
  const userAgent = useSelector(selectUserAgent);

  const impressionPayload = useSelector((state) => ({
    seekerId,
    pageNumber: state.location.pageNumber,
  }));

  const [viewed, setViewed] = useState(false);

  const handleDismiss = useCallback(() => {
    onDismiss?.();
    analyticsFacade.nudgeDismissed({ nudgeType, errorNudgeType });
  }, [analyticsFacade, nudgeType, onDismiss, errorNudgeType]);

  const handlePress = useCallback(
    ({ linkText, href }: NudgeCTAProps) =>
      () => {
        analyticsFacade.nudgePressed({
          nudgeType,
          linkText,
          href,
          errorNudgeType,
        });
      },
    [analyticsFacade, errorNudgeType, nudgeType],
  );

  const handleView = useCallback(() => {
    if (!viewed) {
      if (isBrowserAnalyticsFacade(analyticsFacade)) {
        if (shouldImpressNudge) {
          analyticsFacade.trackNudgeImpression({
            ...impressionPayload,
            device: isMobileUserAgent(userAgent) ? 'mobile' : 'desktop',
            nudgeId: id,
            position,
            sessionId,
          });
        }

        analyticsFacade.nudgeViewed({ nudgeType, errorNudgeType });
      }

      if (shouldFireMetrics) {
        metrics.count('nudges', ['visiblity:tracked']);
      }

      setViewed(true);
    }
  }, [
    analyticsFacade,
    errorNudgeType,
    id,
    impressionPayload,
    nudgeType,
    position,
    sessionId,
    shouldFireMetrics,
    shouldImpressNudge,
    userAgent,
    viewed,
  ]);

  const { ref: trackedComponent, inView } = useInView({
    delay: 1000,
    threshold: 1,
  });

  // When the nudge id changes, we need to reset the viewed state ..
  // .. to ensure we're triggering another view event for the new nudge
  useEffect(() => {
    setViewed(false);
    if (shouldFireMetrics) {
      metrics.count('nudges', ['visiblity:rendered']);
    }
  }, [id, shouldFireMetrics]);

  // When inView changes, we need to trigger view event
  useEffect(() => {
    if (inView) {
      handleView();
    }
  }, [inView, handleView]);

  const alignment = [showIconInMobile ? 'center' : 'left', 'left'] as const;

  return (
    <AnimatedHeight changeKeys={[id]}>
      <Box
        background="neutralSoft"
        borderRadius="large"
        component="aside"
        data={data}
        padding="large"
        ref={trackedComponent}
        width="full"
      >
        <Columns space="medium">
          <Column>
            <Box
              alignItems="center"
              display="flex"
              flexDirection={[showIconInMobile ? 'column' : 'row', 'row']}
              justifyContent="spaceBetween"
            >
              <Box
                display={[showIconInMobile ? 'block' : 'none', 'none']}
                paddingBottom="gutter"
              >
                {Icon && <Icon />}
              </Box>

              <Stack align={alignment} space="medium">
                <Stack align={alignment} space="small">
                  <Text align={alignment} size="large" weight="strong">
                    {heading}
                  </Text>

                  {subHeading ? (
                    <Text align={alignment} size="small" weight="strong">
                      {subHeading}
                    </Text>
                  ) : null}

                  {text ? (
                    <Text align={alignment} size="small" weight="regular">
                      {text}
                    </Text>
                  ) : null}
                </Stack>

                {CTAs.length ? (
                  <Inline space="xsmall">
                    {CTAs.map(({ Cta, linkText, href }, index) => (
                      <Box
                        key={index}
                        onClick={handlePress({ linkText, href })}
                      >
                        <Cta />
                      </Box>
                    ))}
                  </Inline>
                ) : null}
              </Stack>

              <Box paddingLeft="medium" display={['none', 'block']}>
                {Icon && <Icon />}
              </Box>
            </Box>
          </Column>

          {onDismiss ? (
            <Column width="content">
              <Box
                cursor="pointer"
                data-automation="nudge-dismiss"
                onClick={handleDismiss}
                role="button"
              >
                <IconClear tone="neutral" />
              </Box>
            </Column>
          ) : null}
        </Columns>
      </Box>
    </AnimatedHeight>
  );
};
