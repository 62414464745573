import { getSolSearchDataProp } from '@seek/sol-js';
import { useTranslations } from '@vocab/react';
import { Badge, Box, Inline, Text, TooltipRenderer } from 'braid-design-system';
import type { ComponentProps, ReactNode } from 'react';

import { useSearchPath } from 'src/components/Search/SearchBar/SearchBar';
import { useShouldHideJobCount } from 'src/components/SearchResultPage/SearchResultSummary/ShouldHideJobCountDetector';
import { selectTags } from 'src/store/location/selectors';
import { useSelector, useDispatch } from 'src/store/react';
import { useRunSearch } from 'src/store/search/useRunSearch';
import {
  selectAuthenticated,
  selectCompanySuggestion,
  selectFeatureFlag,
  selectIsResultsInError,
  selectIsResultsLoading,
  selectResults,
  selectResultsCount,
  selectSearchQuery,
  selectSolMetadataString,
} from 'src/store/selectors';

import { useBlockDisplayingSearchResultsUntilClientRenders } from '../SplitView/ssaAuthWorkAround';

import translations from './.vocab';
import SortedBy from './SortedBy/SortedBy';
import { getNewToYouBadgeContent } from './helper/getNewToYouBadgeContent';

import * as styleRefs from './SearchResultSummary.css';

const TotalJobsMessageContainer = ({ children }: { children: ReactNode }) => {
  const commonProps: Pick<
    ComponentProps<typeof Box>,
    'id' | 'data' | 'component'
  > = {
    id: 'SearchSummary',
    data: { automation: 'totalJobsMessage' },
    component: 'h1',
  };

  return (
    <Text size="small" {...commonProps}>
      {children}
    </Text>
  );
};

const SearchResultSummary = () => {
  const { t } = useTranslations(translations);

  const searchResultsViewModel = useSelector(selectResults);
  const companySuggestion = useSelector(selectCompanySuggestion);
  const solMetadataString = useSelector(selectSolMetadataString) || '';
  const authenticated = useSelector(selectAuthenticated);
  const isBehaviouralCuesFiltersEnabled = useSelector(
    selectFeatureFlag('behaviouralCuesFilters'),
  );

  const isNewToYou = useSelector(selectTags) === 'new';

  const { summary } = searchResultsViewModel || {};

  const hideJobCount = useShouldHideJobCount();

  const { keywords } = companySuggestion?.search || {};

  const dispatch = useDispatch();

  const runSearch = useRunSearch();

  const lastQuery = useSelector(selectSearchQuery);

  const newToYouTotal =
    useSelector((state) => state.results.totalCountNewToYou) ?? 0;
  const { submitFormPath } = useSearchPath();

  const setIsNewToYou = (val: boolean) => {
    dispatch(
      runSearch({
        pathname: submitFormPath,
        overrideQuery: {
          ...lastQuery,
          tags: val ? 'new' : undefined,
        },
      }),
    );
  };

  const badgeContent = getNewToYouBadgeContent({
    authenticated,
    newToYouTotal,
  });

  const totalAllCount = useSelector(selectResultsCount);

  const resultCount = (isNewToYou ? newToYouTotal : totalAllCount) ?? 0;
  const showSort =
    resultCount > 0 && ((isNewToYou && authenticated) || !isNewToYou);

  // SSA use this hook to prevent rendering search result summary if we are coming
  // directly for 'new to you' from a url.
  const waitCountForLocalRender =
    useBlockDisplayingSearchResultsUntilClientRenders();

  const isError = useSelector(selectIsResultsInError);

  const isLoading = useSelector(selectIsResultsLoading);
  const hideWhenLoadingIfBcuesFilterDisabled =
    isLoading && !isBehaviouralCuesFiltersEnabled;

  if (
    waitCountForLocalRender ||
    isError ||
    hideWhenLoadingIfBcuesFilterDisabled
  ) {
    return null;
  }

  return (
    <Box
      id="searchResultSummary"
      data-automation="searchResultSummary"
      {...getSolSearchDataProp(solMetadataString)}
    >
      <Box
        id="aria-search-bar"
        aria-live="polite"
        aria-labelledby="SearchSummary"
        role="contentinfo"
        alignItems="center"
        display="flex"
        justifyContent={
          !isBehaviouralCuesFiltersEnabled && hideJobCount
            ? 'flexEnd'
            : 'spaceBetween'
        }
        flexWrap="wrap"
        className={styleRefs.container}
      >
        {!isBehaviouralCuesFiltersEnabled && !hideJobCount && (
          <TotalJobsMessageContainer>
            {t('total jobs count found', {
              displayTotalCount: summary?.displayTotalCount || '0',
              totalCount: Number(summary?.displayTotalCount || 0),
              atCompany: keywords
                ? ` ${t('at {company}', { company: keywords })}`
                : '',
              span: (children: ReactNode) => (
                <span key={0} data-automation="totalJobsCount">
                  {children}
                </span>
              ),
            })}
          </TotalJobsMessageContainer>
        )}
        {isBehaviouralCuesFiltersEnabled && (
          <Box
            boxShadow="borderNeutralLight"
            borderRadius="full"
            paddingLeft={isNewToYou ? 'small' : 'none'}
            paddingRight={isNewToYou ? 'none' : 'small'}
          >
            <Inline space="xsmall" alignY="center">
              <Box
                data={{ automation: 'totalJobsCountBcues' }}
                onClick={() => {
                  setIsNewToYou(false);
                }}
                className={{
                  [styleRefs.filterSelection]: !isNewToYou,
                  [styleRefs.cursorPointer]: isNewToYou,
                }}
              >
                <Text>
                  {/* todo: display count should come entirely from searchViewModel, however it's defined in
                  js which makes it difficult to use translations with. Need to address that (maybe
                  hookify it?*/}
                  {!totalAllCount ||
                  hideJobCount ||
                  summary?.displayTotalCount === null
                    ? `${t('All jobs')}`
                    : `${t('{totalCount} jobs', {
                        displayTotalCount: summary?.displayTotalCount || '0',
                        totalCount: Number(summary?.displayTotalCount || 0),
                      })}`}
                </Text>
              </Box>
              <TooltipRenderer
                id="new-to-you-tooltip-renderer"
                placement="bottom"
                tooltip={
                  <Text size="small">{t("Jobs you've not browsed yet")}</Text>
                }
              >
                {({ triggerProps }) => (
                  <Box {...triggerProps} id="new-to-you-filter-text">
                    <Box
                      cursor="pointer"
                      data={{ automation: 'newToYouLabelBcues' }}
                    >
                      <Box
                        onClick={() => {
                          setIsNewToYou(true);
                        }}
                        className={{
                          [styleRefs.filterSelection]: isNewToYou,
                          [styleRefs.cursorPointer]: !isNewToYou,
                        }}
                      >
                        <Inline space="xxsmall" alignY="center">
                          <Box display={{ mobile: 'inline', tablet: 'none' }}>
                            <Text>{t('New')}</Text>
                          </Box>
                          <Box display={{ mobile: 'none', tablet: 'inline' }}>
                            <Text>{t('New to you')}</Text>
                          </Box>

                          {badgeContent !== null && (
                            <Badge
                              tone="positive"
                              data={{
                                automation: 'newToYouJobsCountBcues',
                              }}
                              bleedY={true}
                            >
                              {badgeContent}
                            </Badge>
                          )}
                        </Inline>
                      </Box>
                    </Box>
                  </Box>
                )}
              </TooltipRenderer>
            </Inline>
          </Box>
        )}
        {showSort ? (
          <Box
            style={
              isBehaviouralCuesFiltersEnabled
                ? { marginLeft: 'calc(100% - 40px)', position: 'absolute' }
                : undefined
            }
          >
            {' '}
            <SortedBy />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default SearchResultSummary;
