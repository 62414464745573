import { useTranslations } from '@vocab/react';
import { Box, Column, Columns, Stack, Heading } from 'braid-design-system';

import translations from './.vocab';
import RegisterButton from './RegisterButton';
import SignInButton from './SignInButton';
// @ts-expect-error
import rocketshipImage from './rocketship.png';

const NewToYouSignIn = () => {
  const { t } = useTranslations(translations);

  return (
    <Box
      data-automation="new-to-you-sign-in"
      width="full"
      marginTop={{ mobile: 'large', desktop: 'large' }}
    >
      <Box justifyContent="center" id="newbox" width="full" maxWidth="large">
        <Stack space="large" align="center">
          <Box
            style={{
              background: `url('${rocketshipImage}')`,
              width: '128px',
              height: '128px',
              backgroundSize: 'cover',
            }}
          />
          <Box maxWidth="xsmall" textAlign="center">
            <Heading level="3" weight="weak">
              {t("Sign in to focus on jobs you've not browsed yet")}
            </Heading>
          </Box>
          <Columns
            space={{ mobile: 'large', desktop: 'large' }}
            alignY="center"
            collapseBelow="tablet"
          >
            <Column>
              <SignInButton />
            </Column>
            <Column>
              <RegisterButton />
            </Column>
          </Columns>
        </Stack>
      </Box>
    </Box>
  );
};

export default NewToYouSignIn;
